<template>
  <div :class="classList" class="tracking-wide font-normal drop-shadow">
    <slot></slot>
  </div>
</template>

<script>
const TEXT_CLASSES = {
  "main-xl": " font-ravenholm text-4xl md:text-4xl  lg:text-7xl ",
  "main-xl-num": "font-ravenholm text-5xl md:text-5xl  lg:text-6xl ",
  "main-l": "font-ravenholm text-2xl md:text-2xl  lg:text-4xl ",
  "main-l-num": "font-ravenholm text-3xl md:text-3xl  lg:text-4xl ",
  "main-md-extra": "font-ravenholm text-2xl md:text-2xl  lg:text-3xl ",
  "main-md": "font-ravenholm text-xl md:text-xl  lg:text-3xl ",
  "main-md-num": "font-ravenholm text-xl md:text-xl  lg:text-4xl ",
  "main-sm": "font-ravenholm text-lg md:text-lg  lg:text-xl ",
  "secondary-book": "text-base md:text-base  lg:text-lg ",
  "secondary-sm": "text-sm md:text-sm  lg:text-base",
  "secondary-sm-lite": "text-xs md:text-xs  lg:text-sm",
};

export default {
  props: {
    size: {
      type: String,
      default: "secondary-book",
      validator: (value) => Object.keys(TEXT_CLASSES).includes(value),
    },
    extraClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    classList() {
      return `${TEXT_CLASSES[this.size]} ${this.extraClasses}`;
    },
  },
};
</script>

<!-- No se necesita CSS adicional porque Tailwind CSS maneja los estilos -->
